(function($){
	//Hide the unselected schedules
	$('.et_pb_section.schedule').not('#'+$('#schedule-location-selector').val()).slideUp();

	//Listen for changes to the schedule selector
	$('#schedule-location-selector').change(function(e) {
		let selected = '#'+this.value;

		$('.et_pb_section.schedule').not(selected).slideUp();
		$(selected).slideDown();
	});

})(jQuery);
