// Deal with divi things
(function($) {
	//Remove links from slider titles
	$('.et_pb_slide_title').each(function() {
		if ($(this).find('a').length) {
			$(this).html($(this).find('a').html());
		}
	});

	$(document).ready(function () {
		// Adding aria labels to slider navigation for WCAG
		$('.et-pb-slider-arrows .et-pb-arrow-prev').attr('aria-label', 'previous');
		$('.et-pb-slider-arrows .et-pb-arrow-next').attr('aria-label', 'next');
	});

})(jQuery);
