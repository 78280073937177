// Handle alerts/cookies
(function($) {
	//Get the alert "cookie name", if it exists
	if ($('#notification-bar').length) {
		let cookie = $('#notification-bar').attr('data-alert-date');

		//Check for cookie
		if (document.cookie.indexOf(cookie) == -1) {
			$('#notification-bar').show();
		}

		//Check for cookie clickie
		$('#notification-bar .close').click(function(e) {
			document.cookie = cookie + "=accepted; expires=" + new Date(new Date().getTime()+30*60*60*1000*24).toGMTString() + ';path=/';

			$('#notification-bar').slideUp();
		});
	}

})(jQuery);
