'use strict';

class Theme {
	constructor($) {
		this.$ = $;
	}
}

let $theme = new Theme(jQuery);

require('./polyfills/foreach.js');

//Include theme scripts
require('./menu.js');
require('./modals.js');
require('./schedule-switcher.js');
require('./sliders.js');
require('./sticky-header.js');
require('./gtm-tracking.js');

//Component scripts
require('./components/alerts.js');
require('./components/browser-update-notification.js');
require('./components/divi.js');
require('./components/embeds.js');
require('./components/gravityforms.js');
require('./components/story-modal-popups.js');
require('./components/team-member-modal-popups.js');
require('./components/captcha.js');
