// Production steps of ECMA-262, Edition 5, 15.4.4.18
// Reference: http://es5.github.io/#x15.4.4.18
if (!Array.prototype.forEach) {

  Array.prototype.forEach = function(callback/*, thisArg*/) {

	var T, k;

	if (this == null) {
	  throw new TypeError('this is null or not defined');
	}

	// 1. var O be the result of calling toObject() passing the
	// |this| value as the argument.
	var O = Object(this);

	// 2. var lenValue be the result of calling the Get() internal
	// method of O with the argument "length".
	// 3. var len be toUint32(lenValue).
	var len = O.length >>> 0;

	// 4. If isCallable(callback) is false, throw a TypeError exception.
	// See: http://es5.github.com/#x9.11
	if (typeof callback !== 'function') {
	  throw new TypeError(callback + ' is not a function');
	}

	// 5. If thisArg was supplied, var T be thisArg; else var
	// T be undefined.
	if (arguments.length > 1) {
	  T = arguments[1];
	}

	// 6. var k be 0.
	k = 0;

	// 7. Repeat while k < len.
	while (k < len) {

	  var kValue;

	  // a. var Pk be ToString(k).
	  //    This is implicit for LHS operands of the in operator.
	  // b. var kPresent be the result of calling the HasProperty
	  //    internal method of O with argument Pk.
	  //    This step can be combined with c.
	  // c. If kPresent is true, then
	  if (k in O) {

		// i. var kValue be the result of calling the Get internal
		// method of O with argument Pk.
		kValue = O[k];

		// ii. Call the Call internal method of callback with T as
		// the this value and argument list containing kValue, k, and O.
		callback.call(T, kValue, k, O);
	  }
	  // d. Increase k by 1.
	  k++;
	}
	// 8. return undefined.
  };
}