/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens and enables tab
 * support for dropdown menus.
 */
 (function() {
 	var container, button, menu, links, subMenus;

 	container = document.getElementById('site-navigation');
 	if (! container) {
 		return;
 	}

 	button = container.getElementsByTagName('button')[0];
 	if ('undefined' === typeof button) {
 		return;
 	}

 	menu = container.getElementsByTagName('ul')[0];

	// Hide menu toggle button if menu is empty and return early.
	if ('undefined' === typeof menu) {
		button.style.display = 'none';
		return;
	}

	menu.setAttribute('aria-expanded', 'false');
	if (-1 === menu.className.indexOf('nav-menu')) {
		menu.className += ' nav-menu';
	}

	button.onclick = function() {
		if (-1 !== container.className.indexOf('toggled')) {
			container.className = container.className.replace(' toggled', '');
			button.setAttribute('aria-expanded', 'false');
			menu.setAttribute('aria-expanded', 'false');
		} else {
			container.className += ' toggled';
			button.setAttribute('aria-expanded', 'true');
			menu.setAttribute('aria-expanded', 'true');
		}
	};

	// Get all the link elements within the menu.
	links    = menu.getElementsByTagName('a');
	subMenus = menu.getElementsByTagName('ul');

	// Set menu items with submenus to aria-haspopup="true".
	for (var i = 0, len = subMenus.length; i < len; i++) {
		subMenus[i].parentNode.setAttribute('aria-haspopup', 'true');
	}

	// Each time a menu link is focused or blurred, toggle focus.
	for (i = 0, len = links.length; i < len; i++) {
		links[i].addEventListener('focus', toggleFocus, true);
		links[i].addEventListener('blur', toggleFocus, true);
	}

	/**
	 * Sets or removes .focus class on an element.
	 */
	 function toggleFocus() {
	 	var self = this;

		// Move up through the ancestors of the current link until we hit .nav-menu.
		while (-1 === self.className.indexOf('nav-menu')) {

			// On li elements toggle the class .focus.
			if ('li' === self.tagName.toLowerCase()) {
				if (-1 !== self.className.indexOf('focus')) {
					self.className = self.className.replace(' focus', '');
				} else {
					self.className += ' focus';
				}
			}

			self = self.parentElement;
		}
	}
})();


//
// Mobile Sidebar JS
//
(function($) {

	//Handle the mobile menu toggling
	$('.menu-toggle').click(function() {
		$('.shifter-mcshiftface').hasClass('translated') ? menuClose(): menuOpen();
	});

	//Close the mobile menu when a modal is opened
	$('.open-popup-link').click(function() {
		if ($('.shifter-mcshiftface').hasClass('translated')) {
			menuClose();
		}
	});

	function menuOpen() {
		$('.menu-toggle').toggleClass('cross');
		$('.shifter-mcshiftface').css({ transform: 'translate3d(-30rem, 0, 0)' });
		$('.mobile-menu').css({ transform: 'translate3d(-30rem, 0, 0)' });
		// $('.menu-toggle').css({ transform: 'translate3d(-30rem, 0, 0)' });
		$('.shifter-mcshiftface').addClass('translated');
		$('.sidebar-overlay').fadeIn();
	}

	function menuClose() {
		$('.menu-toggle').removeClass('cross');
		$('.shifter-mcshiftface').css({ transform: 'none' });
		$('.shifter-mcshiftface').removeClass('translated');
		$('.mobile-menu').css({ transform: 'translate3d(0, 0, 0)' });
		$('.sidebar-overlay').fadeOut();
	}

	// Open Sidebar
	$('.sidebar-toggle').click(function (e) {
		$('.shifter-mcshiftface').css({ transform: 'translate3d(30rem, 0, 0)' });
		// $('.menu-toggle').css({ transform: 'translate3d(30rem, 0, 0)' });
		$('.shifter-mcshiftface').addClass('translated');
		$('.sidebar-overlay').fadeIn();
	});

	$('.sidebar-overlay').click(function (e) {
		if($('.shifter-mcshiftface').hasClass('translated')) {
			menuClose();
		}
	});

	// Menu item is clicked
	$('#menu-mobile.menu .menu-item-has-children').on('click', function(e) {
		var self = this;

		function toggle() {
			$(self).toggleClass('open');
			$(self).children('ul').toggleClass('open');
			$(self).parents('ul').find('> li').not(self).toggle();
		}

		// When the user clicks on a menu link
		if($(this).hasClass('open') && e.target.nodeName == 'A') {
			e.preventDefault();

			// Close Menu
			toggle();
		}

		// When a user clicks elsewhere on the menu item
		if(e.target.nodeName !== 'A') {
			e.preventDefault();

			if($(this).hasClass('menu-items-slide-in')) {
				$(this).removeClass('menu-items-slide-in');
				$(this).parent().find('li').addClass('menu-items-slide-out');
			}
			else {
				$(this).addClass('menu-items-slide-in');
				$('.menu-items-slide-out').removeClass('menu-items-slide-out');
			}

			if($(this).parent().hasClass('sub-menu')) {
				$(this).children('ul').toggle().toggleClass('open');
				$(this).parent().children('li').not($(this)).toggle();
				$(this).parent().parent().toggleClass('sub-open');

				return false;
			}
			else {
				toggle();
			}
		}
		else {
			e.preventDefault();
			menuClose();
			window.location = e.target.href;
			return false;
		}
	});

	//
	// Navigation Header JS
	//

	// Show sub-menus on header menu
	$('.menu-main-menu-container li.menu-item-has-children').hover(function () {
		$('.current-menu-item ul').css('display', 'none');
	}, function () {
		$('.current-menu-item ul').css('display', 'block');
	});
})(jQuery);
