(function($) {
	//
	// Fancy file upload
	//
	var gfilewrap = $('div.ginput_container_fileupload');
	if (gfilewrap.length) {
		gfilewrap.find('input, span').hide();
		gfilewrap.parent().append('<div class="fileoverlay"><label class="fileupload">'+baytek.labels.choose_file+'</label><input class="filename" disabled placeholder="'+baytek.labels.no_file+'"></input></div>');

		//Clicking the new HTML triggers the file
		$('.fileupload, .filename').click(function(e){
			e.preventDefault();
			$(e.target).parent().parent().find('input[type=file]').click();
		});

		//Update the fakehtml with the newly uploaded file name
		gfilewrap.find('input[type=file]').on('change', function(e){
			$('.filename').val(this.value ? this.value : baytek.labels.no_file);
		});
	}
})(jQuery);
