(function($){
	var donationScriptLoaded = false;

	$('.open-popup-link').magnificPopup({
		type: 'inline',
		// modal: true,
		closeOnBgClick: false,
		closeOnContentClick: false,
		showCloseBtn: true,
		closeBtnInside: true,
		enableEscapeKey: false,
		callbacks: {
			open: function() {
				//Inject Donation Modal script when it's loaded
				if (this.currItem.src && !donationScriptLoaded) {
					appendDonationScript();
				}
			},
			close: function() {
				//console.log('Modal Closed')
			}
		}
	});

	//link to donation page.
	$('#donation-btn').click(function() {
		window.location = "/donate";
	});


	if (window.location.pathname == '/giving-tuesday/') {
		$.magnificPopup.open({
			items: {
				src: '#payment-modal'
			},
			type: 'inline'
		});
	}
	
	//Check for modal anchor in the url
	let hash = window.location.hash;

	if (hash == '#application-modal') {
		openModal(hash);
	}
	//Also open arbitrarily for #donate and #apply
	else if (hash == '#donate' || hash == '#donate/') {
		// openModal('#donation-modal');
		window.location='/donate';
	}
	else if (hash == '#apply') {
		openModal('#application-modal');
	}

	function openModal(hash = '') {
		$.magnificPopup.open({
			items: {
				src: hash
			},
			type: 'inline'
		});

		//Inject Donation Modal script when it's loaded
		if (hash == '#donation-modal' && !donationScriptLoaded) {
			appendDonationScript();
		}
	}

	function appendDonationScript() {
		//Append the script
		// $('#donation-modal').append('<script id="ch_cdn_embed" type="text/javascript" data-page-id="34583" data-cfasync="false" data-formtype="1" src="https://www.canadahelps.org/services/wa/js/apps/donatenow/embed.min.js"></script><span id="ch_cdn_embed"></span>');

		//Don't do it again
		donationScriptLoaded = true;
	}

})(jQuery);
