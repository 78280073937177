// Team Member Modal Popout
(function($) {
	//On click of a video link, populate the iframe src and call Divi's fitvid
	$('.story .open-popup-link').click(function(e) {
		let $iframe = $($(this).attr('href')).find('iframe').not('.loaded');

		if ($iframe.length) {
			$iframe.attr('src', $iframe.attr('data-src'));
			$iframe.addClass('loaded');

			$iframe.parent().fitVids();
		}
	});

})(jQuery);
