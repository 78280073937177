// Event snippet for Donation conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. 
function gtag_report_conversion_donations(url) {
	var callback = function () {
		if (typeof (url) != 'undefined') {
			// window.location = url;
		}
	};
	gtag('event', 'conversion', {
		'send_to': 'AW-708536214/s4__CODuiK8BEJbP7dEC',
		'value': 20.0,
		'currency': 'USD',
		'transaction_id': '',
		'event_callback': callback
	});
	return false;
} 

// Event snippet for Newsletter Sign-up conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button.
function gtag_report_conversion_newsletter(url) {
	var callback = function () {
		if (typeof (url) != 'undefined') {
			window.location = url;
		}  
	};
	gtag('event', 'conversion', {
		'send_to': 'AW-708536214/8TucCKDtiK8BEJbP7dEC',
		'event_callback': callback
	});
	return false;
}

// Event snippet for Volunteer Form Complete conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. 
function gtag_report_conversion_volunteer(url) {
	var callback = function () {
		if (typeof (url) != 'undefined') {
			window.location = url;
		}
	};
	gtag('event', 'conversion', {
		'send_to': 'AW-708536214/DHfxCKjviK8BEJbP7dEC',
		'value': 10.0,
		'currency': 'USD',
		'event_callback': callback
	});
	return false;
}


(function ($) {
	var currentPath = window.location.pathname;

	// DONATE button is clicked -Trigger Ad Conversion tracking  
	$('#donation-btn').click(function (e) {
		// e.preventDefault();
		return gtag_report_conversion_donations();
	});

	// MailChimp NEWSLETTER signup form is submitted - Trigger Ad Conversion tracking
	$('#mc-embedded-subscribe-form').submit( function(e) {
		if ($('.popup-signup-success')) {
			return gtag_report_conversion_newsletter();
		}		
	});
	$('#SignupForm_0').find('form').submit(function(e) {
		if ($('.popup-signup-success')) {
			return gtag_report_conversion_newsletter();
		}	
	});

	// Homepage PAGE LOAD - Trigger Ad Conversion tracking
	if (currentPath === '/') {
		// Event snippet for Page View conversion page
		return gtag('event', 'conversion', { 'send_to': 'AW-708536214/k2N6CPXq1rsBEJbP7dEC' });
	}

	// VOLUNTEER or CONTACT US form confirmation is loaded - Trigger Ad Conversion tracking 
	$(document).bind('gform_confirmation_loaded', function (event, formId) {
		if (currentPath === '/support/volunteers/') {
			return gtag_report_conversion_volunteer(window.location.href);
		} 
		else if (currentPath === '/connect/') {
			// Contact Us conversion page Event snippet
			return gtag('event', 'conversion', {
				'send_to': 'AW-708536214/ANujCNLTmq8BEJbP7dEC'
			});
		}
	});
	
})(jQuery);