/**
 * Centralized location to handel Canada Helps embed captchas so they don't conflict with each other
 */
window.CaptchaCallback = function() {
	jQuery('.captcha-me').each(function(index, el) {
		let $parent = jQuery(el).parents('form');

		let widgetId = grecaptcha.render(el, {
			'sitekey' : jQuery(el).data('sitekey')
		});

		$parent.attr('data-widget-id', widgetId);
	});
}
