// Team Member Modal Popout
(function($) {
	var timeout;
	var $target = $('.slider-item-team-member .team-member .description');
	var action = function() {
		$target.each((i, description) => {
			$(description).toggleClass('overflowed', isOverflowed($(description)))
		});
	}

	var isOverflowed = function (element) {
		var top = parseInt(element.css('padding-top'))
		var bottom = parseInt(element.css('padding-bottom'))
		var elem = element[0];
		var contentHeight = element.find('> *')
			.map((i, item) =>
				$(item).outerHeight(true)
			)
			.get()
			.reduce((a, b) =>
				a + b
			);

		return contentHeight > elem.clientHeight - (top + bottom);
	}

	$(window).on('resize', function() {
		if(timeout) window.clearTimeout(timeout);

		window.setTimeout(action, 500);
	});


	$('.team-member .reading').each(function() {
		var srcRead = $(this).attr('data-team-member');
		$(this).addClass("read-more-" + srcRead);

		$('.team-member .read-more-' + srcRead).each(function() {
		  	$(this).magnificPopup ({
				type: 'inline',
				items: [
					{
						src: '#'+$(this).attr('data-team-member'),
						type: 'inline'
					}
				],
				closeOnBgClick: true,
				closeOnContentClick: false,
				showCloseBtn: true,
				closeBtnInside: true,
				enableEscapeKey: true,
				callbacks: {
				}
			});
		});
	});

	// Run the check on the heights after above code is rendered.
	action();
})(jQuery);

