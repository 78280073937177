(function($){
	//Set up each slider on the page
	$('.slider').each(function() {
		//Figure out which wrapper the slider should be spawned on
		let classes = $(this).attr('class');

		let slideClass = classes.substr(classes.indexOf('slider-item-'));
		slideClass = '.'+slideClass.substr(0, slideClass.indexOf(' ')).replace('slider-item-','');

		let $slider = $(this).find(slideClass).parent();
		let slideCount = $(this).find(slideClass).length;

		//Figure out controls ID (but use the slider element by default)
		let controlsId = classes.substr(classes.indexOf('slider-controls-'));
		controlsId = controlsId.substr(0, controlsId.indexOf(' ')).replace('slider-controls-','');

		if (controlsId.length) controlsId = '#'+controlsId;

		$controller = (controlsId.length) ? $(controlsId) : $(this);

		//Initialize slick
		$slider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			infinite: true,
			draggable: false,
			appendArrows: $controller,
			adaptiveHeight: true,
			prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous"></button>',
			nextArrow:'<button class="slick-next slick-arrow" aria-label="Next"></button>'
		});

		//Initialize the slide count
		$controller.prepend('<div class="slick-count"><span class="current-position">1</span>&nbsp;/&nbsp;'+slideCount+'</div>');

		let $current = $controller.find('.current-position');

		//Update current position when slide changes
		$slider.on('afterChange', function(e, slick, currentSlide) {
			$current.html(parseInt(currentSlide)+1);
		});
	});

	// For Team Member sliders (using Swiper.js instead of slick, more flexible)
	var swiper1 = new Swiper('.swiper1', {
	  loop: true,
	  hashNavigation: {
	    replaceState: true,
	  },
	  wrapperClass: 'et_pb_code_inner',

      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
	  // Navigation arrows
	  navigation: {
	    nextEl: '.swiper1-button-next',
	    prevEl: '.swiper1-button-prev',
	  },
	  speed: 500,
	});

	var swiper2 = new Swiper('.swiper2', {
	  loop: true,
	  hashNavigation: {
	    replaceState: true,
	  },
	  wrapperClass: 'et_pb_code_inner',

	  pagination: {
        el: '.swiper-pagination-right',
        type: 'fraction',
      },
	  // Navigation arrows
	  navigation: {
	    nextEl: '.swiper2-button-next',
	    prevEl: '.swiper2-button-prev',
	  },
	  speed: 500,
	});

})(jQuery);